import { getInfoModulePath } from '../app-routing-paths';

export const END_USER_AGREEMENT_PATH = 'end-user-agreement';
export const PRIVACY_PATH = 'privacy';
export const THESIS_POLICY_PATH = 'electronic-thesis-and-dissertation-policy';
export const REPOSITORY_POLICY_PATH = 'institutional-repository-policy';


export function getEndUserAgreementPath() {
    return getSubPath(END_USER_AGREEMENT_PATH);
}

export function getPrivacyPath() {
    return getSubPath(PRIVACY_PATH);
}

export function getThesisPolicyPath() {
    return getSubPath(THESIS_POLICY_PATH);
}

export function getRepositoryPolicyPath() {
    return getSubPath(REPOSITORY_POLICY_PATH);
}

function getSubPath(path: string) {
    return `${getInfoModulePath()}/${path}`;
}
