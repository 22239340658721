<footer class="text-lg-start">
  <div *ngIf="showTopFooter" class="top-footer">
    <!-- Grid container -->
    <div class=" container p-4">
      <!--Grid row-->
      <div class="row">

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <h5 class="text-uppercase">Footer Content</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a routerLink="./" class="">Lorem ipsum</a>
            </li>
            <li>
              <a routerLink="./" class="">Ut facilisis</a>
            </li>
            <li>
              <a routerLink="./" class="">Aenean sit</a>
            </li>
          </ul>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <h5 class="text-uppercase">Footer Content</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a routerLink="./" class="">Suspendisse potenti</a>
            </li>
          </ul>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-12 mb-4 mb-md-0">
          <h5 class="text-uppercase">Footer Content</h5>

          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste atque ea quis
            molestias. Fugiat pariatur maxime quis culpa corporis vitae repudiandae aliquam
            voluptatem veniam, est atque cumque eum delectus sint!
          </p>
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </div>
  </div>
  <!-- Grid container -->

  <!-- Copyright -->
  <div class="bottom-footer p-2 pt-4 pb-4 d-flex justify-content-center align-items-center">
    <div class="content-container container">
      <div class="row">

        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
<!--           <h5 class="text-uppercase">Helpful Links</h5>
 -->
          <ul class="list-unstyled mb-0">
            <li>
              <a
             routerLink="info/institutional-repository-policy">Institutional Repository Policy</a>
            </li>
            <li>
              <a
             routerLink="info/electronic-thesis-and-dissertation-policy">Electronic Thesis and Dissertation Policy</a>
            </li>
            <li>
              <a routerLink="info/end-user-agreement">{{ 'footer.link.end-user-agreement' | translate}}</a>
            </li>
            <li>
              <a href="https://up.edu.ph/university-of-the-philippines-up-privacy-notice-foi/">Privacy Notice For FOI</a>
            </li>
            <li>
              <a
             routerLink="info/privacy">Privacy Policy</a>
            </li>
          </ul>
        </div>

        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <ul class="list-unstyled mb-0">
            <li>
              <a href="https://www.upou.edu.ph/home/">UPOU Main Website</a>
            </li>
            <li>
              <a href="https://library.upou.edu.ph/" class="">University Library</a>
            </li>
            <li>
              <a href="https://upou-repository.freshdesk.com/support/home" class="">Get Help</a>
            </li>
          </ul>
        </div>

        <div class="col-lg-4 col-md-12 mb-4 mb-md-0">
          <p>
            Powered by <a
           href="http://www.dspace.org/">DSpace</a>
           <br>
           <a href="#"
             (click)="showCookieSettings()">{{ 'footer.link.cookies' | translate}}</a>
          </p>
        </div>
      </div>
<!--
      <p class="m-0">
        <a
           href="http://www.dspace.org/">{{ 'footer.link.dspace' | translate}}</a>
        {{ 'footer.copyright' | translate:{year: dateObj | date:'y'} }}
        <a class="text-white"
           href="https://www.lyrasis.org/">{{ 'footer.link.lyrasis' | translate}}</a>
      </p>
      Powered by <a class="text-white"
           href="http://www.dspace.org/">DSpace</a>
      <ul class="footer-info list-unstyled small d-flex justify-content-center mb-0">
        <li>
          <a class="text-white" href="#"
             (click)="showCookieSettings()">{{ 'footer.link.cookies' | translate}}</a>
        </li>
        <li>
          <a class="text-white"
             routerLink="info/privacy">{{ 'footer.link.privacy-policy' | translate}}</a>
        </li>
        <li>
          <a class="text-white"
             href="https://up.edu.ph/university-of-the-philippines-up-privacy-notice-foi/">Privacy Notice For FOI</a>
        </li>
        <li>
          <a class="text-white"
             routerLink="info/end-user-agreement">{{ 'footer.link.end-user-agreement' | translate}}</a>
        </li>
      </ul>
    -->
    </div>
  </div>
  <!-- Copyright -->
</footer>
